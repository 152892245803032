import '../stylesheets/style.less';
import './vendor/lity/src/lity.less'
import Plyr from 'plyr';

import $ from 'jquery'
window.jQuery = window.$ = $

/* Author:
	Scott Wise, Compass360
*/

/////////////////////////////////
//
// WINDOW RESIZE FUNCTIONALITY
//
/////////////////////////////////

function resizeLayout() {

  // Re-fire parallax calculations
  //setTimeout(parallax($(window).scrollTop()),200);

  // Adjust the left edge of anything we need to pin
  if ($('.pin-left').length) {
    $('.pin-left').each(function(i,el) {
      // Get this from any element on the layout with "margins" class applied
      var currentPadding = parseInt($('.margins').css('padding-left'));
      // Determine whether we are operating on a relative or absolute element
      var property = $(el).hasClass('relative') ? 'margin-left' : 'left';
      // Define any additional padding we'd need to apply
      var extraPad;
      if ($(window).width() > 1366) {
        extraPad = ($(window).width() - 1366) / 2;
      } else {
        extraPad = 0;
      }
      // Apply the positioning adjustment and padding
      $(el).css(property, (currentPadding * -1) - extraPad);
      $(el).css('padding-left', currentPadding + extraPad);
    });
  }

  // Adjust the right edge of anything we need to pin
  if ($('.pin-right').length) {
    $('.pin-right').each(function(i,el) {
      // Get this from any element on the layout with "margins" class applied
      var currentPadding = parseInt($('.margins').css('padding-right'));
      // Determine whether we are operating on a relative or absolute element
      var property = $(el).hasClass('relative') ? 'margin-right' : 'right';
      // Define any additional padding we'd need to apply
      var extraPad;
      if ($(window).width() > 1366) {
        extraPad = ($(window).width() - 1366) / 2;
      } else {
        extraPad = 0;
      }
      // Apply the positioning adjustment and padding
      $(el).css(property, (currentPadding * -1) - extraPad);
      $(el).css('padding-right', currentPadding + extraPad);
    });
  }

  // Adjust position of carousel arrow buttons
  if ($('#featured-projects').length) {
    // Get the top left position of the image element relative to the container
    var projectsStart = $('#featured-projects .project.order-1').position().top;
    var projectsHeight = $('#featured-projects .project.order-1 .image').height();
    $('#featured-projects .arrow-left, #featured-projects .arrow-right').css({'top': projectsStart + Math.round(projectsHeight/2) - 30 });

    console.log(projectsStart);
    console.log(projectsHeight);
  }
}


//////////////////////////////////////////
//
// NAVIGATION AND SEGMENT FUNCTIONALITY
//
//////////////////////////////////////////

// Define which menu item is "active"
function setActiveItem(segment) {
  $('#nav-header .nav-item').removeClass('active');
  $('#nav-header .nav-item.' + segment).addClass('active');
}

// Compare segment variables to content classes & synchronize
function checkState(currentSegment,currentUrl) {

  // Remove protocol and domain from url string
  currentUrl = currentUrl.replace(/^.*\/\/[^\/]+/, '');

  // Compare the current segment variable to the content container's (prefer the container)
  if (currentSegment != $('#content .container').attr('class')) currentSegment = $('#content .container').attr('id');

  // Then apply the segment and update nav
  $('body').removeClass().addClass(currentSegment);
  if (currentSegment == 'landing') {
    $('#nav-header, footer').removeClass('subpage');
    $('#nav-header .nav-item').removeClass('active');
  } else {
    $('#nav-header, footer').addClass('subpage');
    setActiveItem(currentSegment);
  }
}


////////////////////////////////
//
// LANDING PAGE & CAROUSELS
//
////////////////////////////////

// Image switching on landing page
function swapSlide(index) {
  console.log('swapeSlide: ' + index);
  // Show & hide slide containers
  $('#hero-' + index).addClass('presenting').show().siblings('.hero').removeClass('presenting').hide();
  // Wait for images to load
  $('#hero-' + index).waitForImages(function() {
    console.log('Loaded image');
    // Fade in by removing the cover
    $('#hero-cover').fadeOut(500, function() {
      $('#hero-carousel').removeClass('transitioning');
    });
  });
}

// Image sliding on project page
function slideSlide(index,direction) {
  // Set z-depth of new slide
  $('#hero-' + index).addClass('presenting').show().siblings('.hero').removeClass('presenting');
  // Prepare image start position
  if (direction=='right') {
    $('#hero-' + index).css({'left':'100%','width':0});
  } else {
    $('#hero-' + index).css({'left':0,'width':0});
  }
  // Wait for images to load
  $('#hero-' + index).waitForImages(function() {
    console.log('Loaded image');
    $('#hero-' + index).animate({'left':0,'width':'100%'}, 1000,'swing', function() {
      $('#hero-carousel .hero:not(.presenting)').hide();
      $('#hero-carousel').removeClass('transitioning');
    });
  });
}

// For the History carousel, let's slide the menu up to make more room if we need it
function moveHistoryNav(index) {
  if (index == 0) {
    $('#nav-carousel').animate({'top':'15%'});
  } else {
    $('#nav-carousel').animate({'top':'10%'});
  }
}

// Show/hide arrows at the ends of the carousel
function updateArrows(index) {
  if (!$('#hero-carousel').hasClass('continuous')) {
    if (index == 0) {
      $('.hero-arrow.left').fadeOut(200);
    } else {
      $('.hero-arrow.left').fadeIn(200);
    }
    if (index == $('#hero-carousel .hero').length - 1) {
      $('.hero-arrow.right').fadeOut(200);
    } else {
      $('.hero-arrow.right').fadeIn(200);
    }
  }
}

// Unobfuscate emails
function unobfuscateEmails() {
  // Replace [at] and [dot] in data-tag with proper characters
  $('a[data-email]').each(function () {
    this.href = 'mailto:' + $(this).attr('data-email').replace('[at]', '@').replace(/\[dot]/g, '.');
  });
}

// Carousel functionality
var currentSlide = 0;
function readyCarousels() {
  if ($('#hero-carousel').length) {

    // Track which slide we're currently on
    currentSlide = 0;

    // Bind a click event to switchers on landing page
    $("body").on("click", "#nav-carousel .nav-item", function() {

      if (!$('#hero-carousel').hasClass('transitioning')) {
        $('#hero-carousel').addClass('transitioning');

        // Set active
        $(this).addClass('active').siblings('.nav-item').removeClass('active');

        // Only swap if we're requesting something new
        if (currentSlide != $(this).index()) {
          // Update the slide variable and track the old slide
          currentSlide = $(this).index();
          // Fade in the cover and then swap to the new slide
          $('#hero-cover').fadeIn(500,function() {
            // Swap to requested
            swapSlide(currentSlide);
          });
          // Update arrows
          updateArrows(currentSlide);

          // Scroll to top
          scrollToTarget('#hero-carousel',800);

          // Move nav?
          if ($('#hero-carousel').hasClass('guild-history')) {
            moveHistoryNav(currentSlide);
          }
        }
      }
    });

    // Bind a click event to arrows on project pages
    $("body").on("click", ".hero-arrow", function() {

      if (!$('#hero-carousel').hasClass('transitioning')) {
        $('#hero-carousel').addClass('transitioning');

        // Update the slide variable and track the old slide
        if ($(this).hasClass('right')) {
          if (currentSlide < $('#hero-carousel .hero').length-1) {
            currentSlide++;
          } else {
            currentSlide = 0;
          }
        } else if ($(this).hasClass('left')) {
          if (currentSlide == 0) {
            currentSlide = $('#hero-carousel .hero').length-1;
          } else {
            currentSlide --;
          }
        }

        // Update the menu (if present) to reflect what's active
        $('#nav-carousel .nav-item').eq(currentSlide).addClass('active').siblings('.nav-item').removeClass('active');

        // Slide it or swap it!
        if ($('#hero-carousel').hasClass('slide')) {
          // Slide
          slideSlide(currentSlide,$(this).attr('data-dir'));
        } else {
          // Fade in the cover and then swap to the new slide
          $('#hero-cover').fadeIn(500,function() {
            // Swap to requested
            swapSlide(currentSlide);
          });
        }
        // Update arrows
        updateArrows(currentSlide);

        // Move nav?
        if ($('#hero-carousel').hasClass('guild-history')) {
          moveHistoryNav(currentSlide);
        }
      }
    });

    // Wait for the first image to load and then fade in!
    $('#hero-0').waitForImages(function() {
      // On landing page, we delay presentation
      $('#hero-landing-cover').delay(1500).fadeOut(1200, function() {
        $('#hero-carousel, #nav-carousel').find('.hold').removeClass('hold');
      });
    });
  }

  // Overview Carousel
  if ($('#overview').length) {
    $(document).on("click", "#nav-overview li.nav-item", function(e) {
      e.stopPropagation();

      // Only allow a click if we're not currently transitioning (prevent multiple clicks in quick succession)
      if (!$('#overview').hasClass('transitioning')) {
        $('#overview').addClass('transitioning');

        // If we select the same one twice, revert to the intro
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
          $('#nav-overview').removeClass('js-presenting');
          const fadeOut = { opacity: 0, transition: 'opacity 800ms' };
          $(this).find('ul.nav-sublist').css(fadeOut).slideUp(800,'easeInOutQuad');
          $('#overview .backgrounds .background:visible').fadeOut(800);
          $('#overview .panes .pane.intro').fadeIn(800, function() {
            $('#overview').removeClass('transitioning');
          });
          // Hide the link button
          $('#overview .panes .button').hide();
        } else {
          $('#nav-overview').addClass('js-presenting');
          const fadeOut = { opacity: 0, transition: 'opacity 500ms' };
          $(this).addClass('active').siblings('li.nav-item').removeClass('active').find('ul.nav-sublist').css(fadeOut).slideUp(800,'easeInOutQuad');
          const fadeIn = { opacity: 1, transition: 'opacity 500ms' };
          $(this).find('ul.nav-sublist').css(fadeIn).slideDown(800,'easeInOutQuad');
          var capability = $(this).attr('data-capability');
          $('#overview .panes .pane.intro').fadeOut(300);
          $('#overview .backgrounds .background.' + capability).fadeIn(800).siblings('.background').fadeOut(800, function() {
            $('#overview').removeClass('transitioning');
          });
          // Prepare the link button and show it
          $('#overview .panes .button').attr('href', $(this).attr('data-href'));
          $('#overview .panes .button').show();
        }
      }
    });
  }

  // Case Studies Carousel (for mobile mode only)
  if ($('#featured-projects').length) {
    $(document).on("click", "#featured-projects .arrow-right", function(e) {
      e.stopPropagation();
      var currentCase = parseInt($('#featured-projects').attr('data-showing'));
      var lastCase = currentCase;
      currentCase++;
      if (currentCase > 3) { currentCase = 1;}
      $('#featured-projects').attr('data-showing',currentCase);
      $('#featured-projects .order-' + lastCase).slideUp();
      $('#featured-projects .order-' + currentCase).slideDown();
    });
  }
}



//////////////////////////////////////////
//
// FORM SUBMISSION FUNCTIONALITY
//
//////////////////////////////////////////

function readyForm() {
  if ($('#contact-form').length) {
    $('#contact-form').submit(function(ev) {
      // Prevent the form from actually submitting
      ev.preventDefault();

      var formErrors = false;

      // Clear current errors
      $('#contact-form .has-errors').removeClass('has-errors').find('.errors-popup').remove();

      // Check for required fields
      if (!$('#contact-form #from-email').val()) {
        formErrors = true;
        $('#contact-form #from-email').addClass('field-errors').parents('.field').addClass('has-errors').append('<div class="errors-popup"><ul class="errors-list"><li>A valid email is required.</li></ul></div>');
      } else {
        $('#contact-form #from-email').removeClass('field-errors').parents('.field').removeClass('has-errors').find('.errors-popup').remove();
      }
      if (!$('#contact-form #message').val()) {
        $('#contact-form #message').addClass('field-errors').parents('.field').addClass('has-errors').append('<div class="errors-popup"><ul class="errors-list"><li>A message is required.</li></ul></div>');
        formErrors = true;
      } else {
        $('#contact-form #message').removeClass('field-errors').parents('.field').removeClass('has-errors').find('.errors-popup').remove();
      }

      if (!formErrors) {

        // Send it to the server
        $.post({
          url: '/',
          dataType: 'json',
          data: $(this).serialize(),
          success: function(response) {

            if (!response.error) {
              $('#contact-form').slideUp();
              $('.form-success').html('<h3>Your inquiry was submitted successfully.</h3>').stop().slideDown();
            } else {
              if (response.errors.fromEmail && typeof response.errors.fromEmail !== "undefined") {
                $('#contact-form #from-email').addClass('field-errors').parents('.field').addClass('has-errors').append('<div class="errors-popup"><ul class="errors-list"><li>'+response.errors.fromEmail+'</li></ul></div>');
              } else {
                $('#contact-form #from-email').removeClass('field-errors').parents('.field').removeClass('has-errors').find('.errors-popup').remove();
              }

              if (response.errors.message && typeof response.errors.message !== "undefined") {
                $('#contact-form #message').addClass('field-errors').parents('.field').addClass('has-errors').append('<div class="errors-popup"><ul class="errors-list"><li>'+response.errors.message+'</li></ul></div>');
              } else {
                $('#contact-form #message').removeClass('field-errors').parents('.field').removeClass('has-errors').find('.errors-popup').remove();
              }
            }
          }
        });
      }

    });
  }
}


/////////////////////////////
//
// DOM READY FUNCTIONALITY
//
/////////////////////////////

// On page load...
$(function(){
  'use strict';

  // Lazy-load images
  $('.lazy').Lazy({effect: 'fadeIn', effectTime: 500});

  // Collapsable Main Menu
  $("body").on( "click", "#nav-header #nav-toggle", function(e) {
    e.preventDefault();
    $('#nav-header #nav-menu').toggleClass('expanded');
  });
  $("body").on( "click", "#nav-header li a", function(e) {
    $('#nav-header #nav-menu').toggleClass('expanded');
  });
  $("body").on( "mouseleave", "#nav-header #nav-menu", function(e) {
    $('#nav-header #nav-menu').removeClass('expanded');
  });

  // Ongoing layout adjustments
  $(window).resize(function(){ resizeLayout(); });
  resizeLayout();

  // Page transitions via smoothState.js
  var smoothState = $('#content').smoothState({
    anchors: 'a.page-link',
    debug: false,
    scroll: true,
    allowFormCaching: false,
    prefetch: true,
    cacheLength: 8,
    blacklist: '.no-smoothstate',
    onStart: {
      duration: 1000, // Duration of our animation
      render: function ($container) {
        // Add your CSS animation reversing class
        $container.addClass('is-exiting');
        // Restart your animation
        smoothState.restartCSSAnimations();

        // Fade out the landing video if we're on the homepage
        if ($('body').hasClass('landing')) {
          $('#hero-cover').fadeIn(500);
        }

        // Show the transition loader
        $('.transition-cover').stop().delay(400).fadeIn(900);
      }
    },
    onReady: {
      duration: 0,
      render: function ($container, $newContent) {
        // Remove your CSS animation reversing class
        $container.removeClass('is-exiting');
        // Inject the new content
        $container.html($newContent);
        // Update Analytics, since the tracker is baked into the container
        if (window.ga && ga.loaded) { ga("send", "pageview", $('#content').smoothState().data('smoothState').href); }

        $('.lazy').Lazy({effect: 'fadeIn', effectTime: 500}); // Lazy-load images
        resizeLayout(); // Recalculate sized elements
        checkState(currentSegment,$('#content').smoothState().data('smoothState').href); // Apply proper classes to elements in layout and nav
        readyCarousels(); // Ready the carousels
        readyForm(); // Ready the form
        unobfuscateEmails(); // Unobfuscate emails

        // If the call was to a specific location on the page (ie there's a hash in the URL), scroll to that as a target after load
        if(window.location.hash && $(window.location.hash).length) {
          scrollToTarget(window.location.hash,800);
        }

        // Hide the transition loader and reset
        $('.transition-cover').stop().fadeOut(500).css({"background":""}).removeClass('white');
      }
    }
  }).data('smoothState');

  // Allow smoothState links outside of the container
  var currentSegment;
  $('body').on("click", "#logo-static a.page-link, #nav-header a.page-link, footer a.page-link", function(e) {
    e.preventDefault(); // Prevent normal linking
    e.stopPropagation(); // Prevent menu close wierdness...

    // Get segment/destination information from link
    var href = $(this).attr('href');
    if ($(this).data('segment')) {
      currentSegment = $(this).data('segment');
    } else {
      currentSegment = 'landing';
    }
    smoothState.load(href); // Load the content via smoothState
    $('nav').removeClass('js-open'); // Close mobile nav

    setActiveItem(currentSegment); // Update the nav items

    // Fade out the landing video if we're on the homepage
    if ($('body').hasClass('landing')) {
      $('#hero-cover').fadeIn(500);
    }

    // Collapse the responsive nav
    $('#nav-header').removeClass('expanded');
  });

  // Ready the carousel
  readyCarousels();

  // ready the form
  readyForm();

  // Listeners for modal window events
  $(document).on('lity:open', function(event, instance) {
    // Add a "video ended" listener to the video element, and close the modal when finished
    if ($('video#player').length) {
      $('video#player').on('play', function(){
        // Send an event to Analytics with the class of the video
        // TODO if (window.ga && ga.loaded) { ga("send", "event", 'Video', 'Play', $('video#player').className); }
      });
      $('video#player').on('ended', function(){
        $('video#player').load(); // Reload video to start from beginning if the user re-opens
        $('[data-lity-close]', window.parent.document).trigger('click'); // Because close function isn't exposed, we can "trigger" the close button instead
        // Send an event to Analytics with the class of the video
        // TODO if (window.ga && ga.loaded) { ga("send", "event", 'Video', 'Completed', $('video#player').className); }
      });
    }
  });
  $(document).on('lity:close', function(event, instance) {
    // Stop (pause) playback and rewind in modal on close
    if ($('video#player').length) {
      $('video#player').get(0).pause();
      $('video#player').get(0).currentTime = 0;
    }
  });

  // Scroll to target (using href)
  $(document).on("click", "a.scroll-link", function(e) {
    e.preventDefault();
    scrollToTarget($(this).attr('href'),800);
  });

  // Expand and collapse job postings on career page
  $('body').on("click", "#postings .posting .heading", function(e) {
    var posting = $(this).parent('.posting');
    if (posting.hasClass('expanded')) {
      collapsePosting(posting);
    } else {
      // Expand this posting and collapse all others
      posting.addClass('expanded').siblings('.posting').each(function(i,el) {
        collapsePosting(el);
      });
      posting.find('.details').slideDown(800, function() {
        //$("html,body").animate({ scrollTop: posting.offset().top - 80 }, 500);
      });
    }
  });
  // Collapse a given posting
  function collapsePosting(posting) {
    $(posting).find('.details').slideUp(500);
    $(posting).removeClass('expanded');
  }

  // Unobfuscate emails
  unobfuscateEmails();
});



/////////////////////////////
//
// ON SCROLL FUNCTIONALITY
//
/////////////////////////////

// Move elements at different speed depending on their level
function parallax(scroll) {
  // Top-most layers float
  $('.level-1, .level-2, .level-3').each(function(i,el) {
    var thisTop = parseInt($(el).css('top')); // Read current CSS top attr as px val
    var yPos;
    if ($(el).hasClass('level-1')) {
      yPos = -(scroll-$(el).offset().top+thisTop)/8; // Calculate
    } else if ($(el).hasClass('level-2')) {
      yPos = -(scroll-$(el).offset().top+thisTop)/6.5; // Calculate
    } else if ($(el).hasClass('level-3')) {
      yPos = -(scroll-$(el).offset().top+thisTop)/5; // Calculate
    }
    if ($(el).hasClass('limit')) {
      if (yPos > $(el).attr('data-limit')) { yPos = $(el).attr('data-limit'); }
    }
    if ($(el).hasClass('offset')) {
      yPos += parseInt($(el).attr('data-offset'));
    }
    if ($(el).hasClass('transform')) {
      $(el).css({'transform':'translateY('+yPos+'px)'}); // Apply
    } else {
      $(el).css({'margin-top':yPos}); // Apply
    }
  });
  // Backdrop layers recede
  $('.level-back').each(function(i,el) {
    var centerX = '0px ';
    var yPos;
    // Since some of our backdrops are hidden until interaction, we can fallback to the section parent to calculate the offset
    if ($(el).is(':visible')) {
      yPos = (scroll-$(el).offset().top)/8;
    } else {
      yPos = (scroll-$(el).parents('section').offset().top)/8;
    }
    if ($(el).hasClass('reverse')) { yPos *= -1; }
    if ($(el).hasClass('center-x')) { centerX = 'center '; }
    if ($(el).hasClass('custom-x')) { centerX = $(el).attr('data-custom-x'); }
    if ($(el).hasClass('center-y')) {
      $(el).css({'background-position': centerX + 'calc(50% + ' + yPos + 'px)'})
    } else {
      $(el).css({'background-position': centerX + yPos + 'px'})
    }
  });

  // Pin to bottom edge?
  $('.level-back-bottom').each(function(i,el) {
    $(el).css({'background-position': 'left 0px bottom ' + (scroll-$(el).offset().top)/-4 + 'px'});
  });

  // Scroll logo in footer
  // console.log(scroll);
  var footerOffset = $(document).height() - scroll - $(window).height();
  $('footer .logo').css({'margin-top': footerOffset/8 + 'px'});
}

// Wrap the scroll targetting in a function we can call from multiple places
function scrollToTarget(targetId,duration) {

  var scrollTarget = $(targetId);

  // Scroll to the element
  $("html,body").animate({ scrollTop: $(scrollTarget).offset().top }, duration);
  $(this).addClass('active').siblings('.nav-item').removeClass('active');
}

// Animate on any hero elements (this only happens once)
function revealAnimations(scroll) {
  // Animate-on any elements with the reveal class
  if ($('.reveal').length) {
    $('.reveal').each(function(i,el) {
      var threshold;
      if ($(el).hasClass('instant')) {
        threshold = $(window).height() - 100;
      } else {
        threshold = $(window).height()/3*2;
      }

      if (scroll + threshold > $(el).offset().top) {
        $(el).removeClass('reveal').addClass($(el).attr('data-addclasses'));
      }
    });
  }

  // Animate on any sub-elements
  if ($('.reveal-children').length) {
    $('.reveal-children').each(function(i,el) {
      var threshold;
      if ($(el).hasClass('instant')) {
        threshold = $(window).height() - 100;
      } else {
        threshold = $(window).height()/3*2;
      }

      if (scroll + threshold > $(el).offset().top) {
        var classes = $(el).attr('data-addclasses');
        $(el).find('.reveal-child').each(function(j,sub) {
          $(sub).addClass(classes + ' delay-small-' + j);
        });
        $(el).removeClass('reveal-children')
      }
    });
  }
}

// Bid scroll events to window element
$(window).scroll(function(){

  // Set current scroll position to a variable we can use
  var scroll = $(window).scrollTop();

  // Handle element parallax
  parallax(scroll);

  // Process reveal animations
  revealAnimations(scroll);

  // Hide menu label for nav and reduce logo size
  if (scroll > 200) {
    $('#nav-header').addClass('hide-label');
    $('#logo-static').addClass('small');
  } else {
    $('#nav-header').removeClass('hide-label');
    $('#logo-static').removeClass('small');
  }

  // Detect whether we are scrolling through a light background section
  var onLight = false;
  $('section.light-bg').each(function(i,el) {
    var elTop = $(el).offset().top;
    var elHeight = $(el).outerHeight();
    if (scroll + 90 > elTop && scroll + 90 < elTop + elHeight + 60) onLight = true;
  });
  if (onLight) {
    $('#nav-header').addClass('on-white');
  } else {
    $('#nav-header').removeClass('on-white');
  }
});
